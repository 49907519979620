
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { isRequired } from '@/utils/validation'
import { dateDashNotationToDot, dateDotNotationToDash } from '@/utils/convertDate'

import { useCreateWorkingDaysPlanned, useUpdateWorkingDaysPlanned } from '@/api/workingDaysPlanned'

import { isOfType } from '@/utils/types/isOfType'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { NewWorkingDaysPlanned, WorkingDaysPlanned } from '@/api/types/workingDaysPlanned'

export default defineComponent({
  name: 'AddEditWorkingDaysPlannedDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workingDaysPlannedToEdit: {
      type: Object as PropType<WorkingDaysPlanned | null>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.workingDaysPlannedToEdit))

    const { addNotification } = useNotify()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'month',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.timePlanning.components.workingDaysPlanned.form.month') as string),
        ],
      },
      {
        value: 'daysPlanned',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(
              value,
              root.$t('planning.timePlanning.components.workingDaysPlanned.form.daysPlanned') as string
            ),
        ],
      },
      {
        value: 'updateExistingWorkingHours',
        fieldType: FORM_FIELDS_ENUM.CHECKBOX,
        dataTyp: DATA_TYPE.BOOLEAN,
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<NewWorkingDaysPlanned | WorkingDaysPlanned>(
      props.workingDaysPlannedToEdit
        ? cloneDeep(props.workingDaysPlannedToEdit)
        : ({
            updateExistingWorkingHours: false,
          } as NewWorkingDaysPlanned)
    )

    const { createWorkingDaysPlanned, isLoading: isLoadingCreateWorkingDaysPlanned } = useCreateWorkingDaysPlanned()

    async function onAdd(): Promise<void> {
      if (!isOfType<WorkingDaysPlanned>(form.value, 'id')) {
        const updatedForm: NewWorkingDaysPlanned = cloneDeep(form.value)
        updatedForm.month = dateDotNotationToDash(updatedForm.month)

        try {
          await createWorkingDaysPlanned(updatedForm)

          close()

          addNotification({
            text: root.$t('planning.timePlanning.components.workingDaysPlanned.create.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateWorkingDaysPlanned, isLoading: isLoadingUpdateWorkingDaysPlanned } = useUpdateWorkingDaysPlanned()

    async function onEdit(): Promise<void> {
      if (isOfType<WorkingDaysPlanned>(form.value, 'id')) {
        const updatedForm: WorkingDaysPlanned = cloneDeep(form.value)
        updatedForm.month = dateDotNotationToDash(updatedForm.month)

        try {
          await updateWorkingDaysPlanned(updatedForm.id, updatedForm)

          close()

          addNotification({
            text: root.$t('planning.timePlanning.components.workingDaysPlanned.edit.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditWorkingDaysPlanned = computed(
      () => isLoadingCreateWorkingDaysPlanned.value || isLoadingUpdateWorkingDaysPlanned.value
    )

    watch(
      () => props.workingDaysPlannedToEdit,
      () => {
        if (props.workingDaysPlannedToEdit) {
          form.value = cloneDeep(props.workingDaysPlannedToEdit)
          form.value.month = dateDashNotationToDot(form.value.month)
        }
      },
      { immediate: true }
    )

    function close(): void {
      emit('added-edited')
      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditWorkingDaysPlanned,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
